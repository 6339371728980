@use "sass:math";

/* general style rules */
@import './stylevariables.scss';

@import "~bootstrap/scss/bootstrap";
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';
@import "~@ng-select/ng-select/themes/default.theme.css";


@keyframes fade {
  from { opacity: 1.0; }
  50% { opacity: 0.3; }
  to { opacity: 1.0; }
}

@-webkit-keyframes fade {
  from { opacity: 1.0; }
  50% { opacity: 0.5; }
  to { opacity: 1.0; }
}

/* ONLY FOR DUMMY PAGES!!!! */

.dummypanel-image {
  height: calc(100% - 100px);
}

/* END ONLY FOR DUMMY PAGES!!!!!*/

body {
  min-width: 1200px;
  overflow-y: hidden;
}

a {
  color: inherit !important;
  text-decoration: none;
  &:hover {
    color: $primary !important;
    text-decoration: underline;
  }
}

.pageload-animation {
  animation:fade 2000ms infinite;
  -webkit-animation:fade 2000ms infinite;
}

.pageload-text {
  display: block;
  color: $primary;
  font-size: 1em;
  padding-top:10px;
}

.cursor-pointer {
  cursor: pointer;
}

.nomargin {
  margin: 0px !important;
}

.cropper-container img {
  display: none !important;
}

.color_primary {
  color: $primary;
}

.color_secondary {
  color: $secondary;
}

.bgcolor_secondary {
  background-color: $secondary;
}

.markup-grey {
  border-top: 1px solid $gray-400;
  border-bottom: 1px solid $gray-400;
  background-color: #f7f8fa;
  padding: 5px;
}

.shorten-long-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-main {
  border-right: 3px solid $secondary;
}

.card-main-left-grey {
  border-left: 3px solid $secondary;
  background-color: #f7f8fa;
}

/* modify select */
.select-invisible {
  width: 93%;
  .ng-select-container {
    border:none;
  }
  .ng-arrow-wrapper {
    display:none;
  }
}

/* modify bootstrap */

.btn-xs {
  @extend .btn-sm;
  padding: 0.1rem 0.5rem 0.15rem;
  font-size: 0.75rem;
}

.btn-primary {
  @extend .btn-primary;
  background-color: #009eff;
  border-color:transparent;
}

.btn-info {
  @extend .btn-info;
  background-color: #80cfff;
}

.btn-error {
  @extend .btn-error;
  background-color: $errorlight;
}

.btn-warning {
  @extend .btn-warning;
  background-color: #f0ad4e;
  color:#ffffff;
  &:hover {
    color:#ffffff !important;
  }
}

.btn-success {
  @extend .btn-success;
  background-color: #5cb85c;
}

.badge {
  @extend .badge;
  padding: 0.5em 0.8em 0.5em;
  font-size: 90%;
  font-weight:normal;
}

.badge-info {
  @extend .badge-info;
  background-color: #80cfff;
  &:hover {
    background-color: #80cfff !important;
  }
}

.badge-warning {
  @extend .badge-warning;
  background-color: #f0ad4e;
  color: #ffffff;
  &:hover {
    background-color: #f0ad4e !important;
  }
}

.badge-success {
  @extend .badge-success;
  background-color: #5cb85c;
  &:hover {
    background-color: #5cb85c !important;
  }
}

.badge-error {
  @extend .badge-error;
  background-color: $errorlight;
  &:hover {
    background-color: $errorlight !important;
  }
}


.tooltip-inner {
  @extend .tooltip-inner;
  background-color: #eeeeee !important;
  border-color: #666666 !important;
  color: #666666 !important;
}

.arrow::before {
  @extend .arrow;
  border-top-color: #eeeeee !important;
}

/* modify ng-select */

.ng-select-container {
  @extend .ng-select-container;
  border-radius: 0px !important;
}

/* modify ngx-contextmenu */
.ngx-contextmenu {
  @extend .ngx-contextmenu;

  ul {
    &:focus {
      outline: none !important;
    }
  }
  li {
    &:focus {
      outline: none !important;
    }
  }
  a {
    &:active {
      background-color: transparent !important;
    }
  }

}


/* modify ngx-datatable */

.ngx-datatable.scroll-vertical .datatable-body {
  margin-bottom: 1px;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: $secondarylight;
  color: #000000;
}


.ngx-datatable.bootstrap .datatable-body .datatable-body-row :hover {
  background-color: $secondarylight2;
}


.datatable-checkbox {
  padding-top: 2px;
  margin: 0px;
}

.datatable-header-cell-template-wrap .datatable-checkbox {
  padding-top: 4px;
  margin: 0px;
}

.datatable-header-cell-label {
  @extend .datatable-header-cell-label;
  font-weight: 600;
}

.datatable-footer {
  @extend .datatable-footer;
  background: $primary !important;
  color: $white !important;
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
  line-height: 30px !important;
  height: 30px !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  margin: 0px 0px !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  display: flex;
  align-items: center !important;
  &:hover {
    background-color: transparent !important;
  }
}

.pager {
  @extend .pager;

  li {
    &:hover a {
      background-color: $white !important;
      color: $gray-800 !important;
    }
  }

  a {
    color: $white !important;
    border-radius: 0px !important;
  }

  .active a{
    background-color: $white !important;
    color: $gray-800 !important;
  }
}

/* ********************************************************** */
/* optionally create new custom classes from existing classes */

/* NavTabs */

.nav-tabs {
  border-color: #ced4da;
  background-color: #e7e8ea;
  color: #666;

  .nav-link {
    color: #666;
    &:hover {
      background: $secondarylight2 !important;
    }
  }

  .nav-link.active {
    background: $secondary !important;
    color: $light !important;
  }
}

.navbar-tabs-light {
  .nav-tabs {
    border-color: #ced4da !important;
    background-color: #ffffff !important;
    color: #666 !important;

    a {
      padding: 0.2em 0.4em 0.3em;
    }

    .nav-link {
      color: #666 !important;
      &:hover {
        background: #e7e8ea !important;
      }
    }

    .nav-link.active {
      background-color: #e7e8ea !important;
      color: #666 !important;
    }

  }
}


/* NavBars */

.navbar-header {
  @extend .navbar-light;
  border-bottom: $primary 2px solid;
  padding-top: 0;
  padding-bottom: 0px;
}
.navbar-footer {
  @extend .navbar-light;
  border-top: $gray-400 1px solid;
  background-color: $white;
  padding: 0;
}

.navbar-brand {
  @extend .navbar-brand;
  padding: 0.8125rem 1.3125rem;
}

.navbar-nav-top {
  @extend .navbar-nav;
  margin-top: 1.815rem;
  margin-left: 2rem;

  .nav-item{
    margin-bottom: -2px;
    padding: 0.3125rem 2rem !important;
    border-bottom: $primary 2px solid;
  }
  .nav-link{
    color: $gray-800 !important;
    border-top: transparent 1px solid;

    &:hover {
      background-color: $gray-200;
    }
  }
  .active{
    margin-top: -1px;
    border-top: $primary 2px solid;
    border-bottom: $white 2px solid;
    border-left: $gray-400 1px solid;
    border-right: $gray-400 1px solid;
  }

}

.nav-sidebar {
  @extend .nav;

  .nav-link{
    color: $gray-800 !important;
    padding-left: 1.5rem;

    &:hover{
      background-color: $secondary;
      color: $white !important;
    }
  }
  .sidebar-sublink{
    padding-left: 2.5rem;
  }

  .active{
    color: $white !important;
    background-color: $secondary;
    border-right: $primary 4px solid;
  }
}

.sidebar-iconbox{
  display: -webkit-inline-box;
  display: inline-block;
  width: 25px;
  text-align: center;
}

.sidebar-title{
  font-weight: 600;
  font-size: 1rem;
  margin-left: 1.3125rem;
  margin-top: 1rem;
  color: $primary;
}

.sidebar-headline{
  font-weight: 600;
  font-size: 1rem;
  margin-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mainframe{
  margin:0;
  padding:0;
  margin-top:-48px;
}

.sidebar{
  height: calc(100vh - 64px);
  width:  220px;
  overflow-y: auto;
  float: left;
  background-color: $gray-100;
  border-right: $gray-400 1px solid;
}

.contentframe {
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.contentframe_inner {
  margin: 1.5rem;
}

.stdcontent {
  padding: 1.3125rem;
  background-color: $white;
  border-right:0px;
  height:2200px;
}

.content {
  padding-left:1.7rem;
  font-size:0.8rem;
}



/* ********************************************************
***** SLIDER LAYOUT
*/

@mixin rounded($radius: 0px) {
  -webkit-border-radius: 0;
     -moz-border-radius: 0;
          border-radius: 0;
}

$handleActiveColor: #A1A1A1 !default;
$handleHoverColor: #fff !default;
$labelTextColor: #55637d !default;
$handleBgColor: #b9e2a7 !default;
$handleInnerColor: rgb(8, 7, 7) !default;
$handleDisabledColor: #d8e0f3 !default;
$limitLabelTextColor: $labelTextColor !default;
$barFillColor: $handleBgColor !default;
$barDisabledFillColor: #8b91a2 !default;
$barNormalColor: #d8e0f3 !default;
$barLeftOutSelectionColor: #C1C1C1 !default;
$barRightOutSelectionColor: #C1C1C1 !default;

$ticksColor: $barNormalColor !default;
$selectedTicksColor: $barFillColor !default;
$ticksWidth: 10px !default;
$ticksHeight: 10px !default;
$ticksValuePosition: -34px !default;
$ticksLegendPosition: 24px !default;
$ticksValuePositionOnVertical: 24px !default;
$tickLegendMaxWidth: 50px !default;

$handleSize: 22px !default;
$handlePointerSize: 19px !default;
$bubblePaddingVertical: 1px !default;
$bubblePaddingHorizontal: 3px !default;
$labelFontSize: 1em !default;
$barDimension: 4px !default;

$withLegendMargin: 40px !default;

$sliderMargin: 15px !default;
$sliderMarginWithLabel: 35px !default;
$sliderVerticalMargin: 20px !default;

$animationDuration: 0.1s !default;

// We need to use ::ng-deep to overcome view encapsulation
.custom-slider {
  .ng5-slider {
    display: inline-block;
    position: relative;
    height: $barDimension;
    width: 100%;
    margin: $sliderMarginWithLabel 0 $sliderMargin 0;
    vertical-align: middle;
    user-select: none;
    touch-action: pan-y;

    &.with-legend {
      margin-bottom: $withLegendMargin;
    }

    &[disabled] {
      cursor: not-allowed;

      .ng5-slider-pointer {
        cursor: not-allowed;
        background-color: $handleDisabledColor;
      }

      .ng5-slider-draggable {
        cursor: not-allowed;
      }

      .ng5-slider-selection {
        background: $barDisabledFillColor;
      }

      .ng5-slider-tick {
        cursor: not-allowed;

        &.ng5-slider-selected {
          background: $barDisabledFillColor;
        }
      }
    }

    .ng5-slider-span {
      white-space: nowrap;
      position: absolute;
      display: inline-block;
    }

    .ng5-slider-base {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .ng5-slider-bar-wrapper {
      left: 0;
      box-sizing: border-box;
      margin-top: - math.div($handleSize, 2);
      padding-top: math.div($handleSize, 2);
      width: 100%;
      height: $handleSize;
      z-index: 1;
    }

    .ng5-slider-draggable {
      cursor: move;
    }

    .ng5-slider-bar {
      left: 0;
      width: 100%;
      height: $barDimension;
      z-index: 1;
      background: $barNormalColor;
    }

    .ng5-slider-bar-wrapper.ng5-slider-transparent .ng5-slider-bar {
      background: transparent;
    }

    .ng5-slider-bar-wrapper.ng5-slider-left-out-selection .ng5-slider-bar {
      background: $barLeftOutSelectionColor;
    }

    .ng5-slider-bar-wrapper.ng5-slider-right-out-selection .ng5-slider-bar {
      background: $barRightOutSelectionColor;
    }

    .ng5-slider-selection {
      z-index: 2;
      background: $barFillColor;
      @include rounded(math.div($barDimension, 2));
    }

    .ng5-slider-pointer {
      cursor: pointer;
      width: math.div($handleSize, 2);
      height: $handleSize;
      top: - math.div($handleSize, 2) + math.div($barDimension, 2);
      background-color: #A1A1A1;
      z-index: 3;
      @include rounded(math.div($handleSize, 2));

      &:after {
        content: '';
        width: math.div($handlePointerSize, 2);
        height: $handlePointerSize;
        position: absolute;
        top: math.div($handleSize, 2) - math.div($handlePointerSize, 2);
        left: math.div($handleSize, 4) - math.div($handlePointerSize, 4);
        @include rounded(math.div($handlePointerSize, 2));
        background: #C1C1C1;
      }

      &:hover:after {
        background-color: $handleHoverColor;
      }

      &.ng5-slider-active {
        z-index: 4;

        &:after {
          background-color: #A1A1A1;
        }
      }
    }

    .ng5-slider-bubble {
      cursor: default;
      bottom: math.div($handleSize, 2);
      padding: $bubblePaddingVertical $bubblePaddingHorizontal;
      color: $labelTextColor;
      font-size: $labelFontSize;

      &.ng5-slider-limit {
        color: $limitLabelTextColor;
      }
    }

    .ng5-slider-ticks {
      box-sizing: border-box;
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      top: - math.div($ticksHeight - $barDimension, 2);
      margin: 0;
      z-index: 1;
      list-style: none;
    }

    .ng5-slider-ticks-values-under {
      .ng5-slider-tick-value {
        top: auto;
        bottom: $ticksValuePosition - 2;
      }
    }

    .ng5-slider-tick {
      text-align: center;
      cursor: pointer;
      width: $ticksWidth;
      height: $ticksHeight;
      background: $ticksColor;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: math.div($handleSize, 4) - math.div($ticksWidth, 2); // for centering

      &.ng5-slider-selected {
        background: $selectedTicksColor;
      }
    }

    .ng5-slider-tick-value {
      position: absolute;
      top: $ticksValuePosition;
      transform: translate(-50%, 0);
    }

    .ng5-slider-tick-legend {
      position: absolute;
      top: $ticksLegendPosition;
      transform: translate(-50%, 0);
      max-width: $tickLegendMaxWidth;
      white-space: normal;
    }

    &.vertical {
      position: relative;
      width: $barDimension;
      height: 100%;
      margin: 0 $sliderVerticalMargin;
      padding: 0;
      vertical-align: baseline;
      touch-action: pan-x;

      .ng5-slider-base {
        width: 100%;
        height: 100%;
        padding: 0;
      }

      .ng5-slider-bar-wrapper {
        top: auto;
        left: 0;
        margin: 0 0 0 (- math.div($handleSize, 2));
        padding: 0 0 0 math.div($handleSize, 2);
        height: 100%;
        width: $handleSize;
      }

      .ng5-slider-bar {
        bottom: 0;
        left: auto;
        width: $barDimension;
        height: 100%;
      }

      .ng5-slider-pointer {
        left: - math.div($handleSize, 2) + math.div($barDimension, 2) !important;
        top: auto;
        bottom: 0;
      }

      .ng5-slider-bubble {
        left: math.div($handleSize, 2) !important;
        bottom: 0;
      }

      .ng5-slider-ticks {
        height: 100%;
        width: 0;
        left: - math.div($ticksHeight - $barDimension, 2);
        top: 0;
        z-index: 1;
      }

      .ng5-slider-tick {
        vertical-align: middle;
        margin-left: auto;
        margin-top: math.div($handleSize, 2) - math.div($ticksWidth, 2); // for centering
      }

      .ng5-slider-tick-value {
        left: $ticksValuePositionOnVertical;
        top: auto;
        transform: translate(0, -28%);
      }

      .ng5-slider-tick-legend {
        top: auto;
        right: $ticksLegendPosition;
        transform: translate(0, -28%);
        max-width: none;
        white-space: nowrap;
      }

      .ng5-slider-ticks-values-under {
        .ng5-slider-tick-value {
          bottom: auto;
          left: auto;
          right: $ticksValuePositionOnVertical;
        }
      }
    }

    * {
      transition: none;
    }

    &.animate {
      .ng5-slider-bar-wrapper {
        transition: all linear $animationDuration;
      }

      .ng5-slider-selection {
        transition: background-color linear $animationDuration;
      }

      .ng5-slider-pointer {
        transition: all linear $animationDuration;
      }

      .ng5-slider-bubble {
        transition: all linear $animationDuration;

        &.ng5-slider-limit {
          transition: opacity linear $animationDuration;
        }

        &.ng5-slider-combined {
          transition: opacity linear $animationDuration;
        }
      }

      .ng5-slider-tick {
        transition: background-color linear $animationDuration;
      }
    }
  }
}
