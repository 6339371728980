/* general style rules */
$enable-rounded:            false;
$light:         #ffffff;
$primary:       #000542;
$secondary:     #009eff;
$secondarylight:     #80cfff;
$secondarylight2:     #ccecff;
$error: #721c24;
$errorlight: #f5c6cb;
$errorlight2: #f8d7da;
$body-bg:         transparent;
$font-size-base:  0.875rem; // Assumes the browser default, typically `16px`
